<template>
  <div>
    <b-card bg-variant="light" header="Cierre de visita" header-bg-variant="primary" header-text-variant="white" align="center" >
        
        <b-form @submit="modalEnviar" @reset="cancelar" align="left" id="formularioResiduos">
            <b-form-row>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-req_visita_seguimiento"
                        label="Req. Visita de Seguimiento"
                        label-for="req_visita_seguimiento"
                    >
                        <b-form-select id="req_visita_seguimiento" v-model="form.req_visita_seguimiento" class="mb-4" required :disabled="verDetalles?true:false">
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4"  v-if="form.req_visita_seguimiento==1">
                    <b-form-group
                        id="group-fecha_visita_seg"
                        label="Fecha visita seguimiento"
                        label-for="fecha_visita_seg"
                       
                    >
                        <b-form-datepicker 
                            id="fecha_visita_seg"
                            v-model="form.fecha_visita_seg"
                            placeholder="DD/MM/YYYY"
                            labelNoDateSelected="No hay fecha"
                            labelHelp="Use las flechas del teclado para navegar entre fechas"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :disabled="verDetalles?true:false"
                        ></b-form-datepicker >
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-permite_reg_foto"
                        label="Permite registro fotografico"
                        label-for="permite_reg_foto"
                    >
                        <b-form-select id="permite_reg_foto" v-model="form.permite_reg_foto" class="mb-3" required :disabled="verDetalles?true:false">
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

            </b-form-row>

            <b-form-row>

                <b-col cols="12" md="6">
                    <b-form-group
                        id="group-firma_funcionario"
                        label="Firma funcionario"
                        label-for="firma_funcionario"
                    >
                        <vueSignature ref="firma_funcionario" :sigOption="optionPad" :disabled="verDetalles?true:false"></vueSignature>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6" v-if="!activarAlterno">
                    <b-form-group
                        id="group-firma_atiende_visita"
                        label="Firma atiende visita"
                        label-for="firma_atiende_visita"
                    >
                        <vueSignature ref="firma_atiende_visita" :sigOption="optionPad" :disabled="verDetalles?true:false"></vueSignature>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="6" v-if="activarAlterno">
                    <b-form-group
                        id="group-firma_alterno"
                        label="Firma alterno"
                        label-for="firma_alterno"
                    >
                        <vueSignature ref="firma_alterno" :sigOption="optionPad" :disabled="verDetalles?true:false"></vueSignature>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-permite_reg_foto"
                        label-for="permite_reg_foto"
                    >
                        <b-button @click="activarCampoAlterno" variant="primary" class="mt-4" size="sm" :disabled="verDetalles?true:false">
                            Activar Datos alternos
                        </b-button>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="activarAlterno">
                    <b-form-group
                        id="group-nombre_alterno"
                        label="Nombre alterno"
                        label-for="nombre_alterno"
                    >
                        <b-form-input
                            id="nombre_alterno"
                            v-model="form.nombre_alterno"
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="activarAlterno">
                    <b-form-group
                        id="group-iden_alterno"
                        label="Identificación alterno"
                        label-for="iden_alterno"
                    >
                        <b-form-input
                            id="iden_alterno"
                            v-model="form.iden_alterno"
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" class="text-right">
                    <b-button type="submit" variant="primary" class="mr-2" :disabled="verDetalles?true:false">
                        Guardar
                    </b-button>
                    <b-button type="reset" >Cancelar</b-button>
                </b-col>

            </b-form-row>

        </b-form>
    </b-card>
  </div>
</template>

<script>
    import vueSignature from "vue-signature"
import { mapActions, mapState } from 'vuex'
    import {encontrarError} from '../../contantesErrores';
    export default {
        name: 'CierreVisita',
        components:{
            vueSignature
        },
        data(){
            return {
                vigilanciaControl: false,
                activarAlterno: false,
                editar: false,
                optionPad:{
                    penColor:"rgb(0, 0, 0)",
                    backgroundColor:"rgb(255,255,255)"
                },
                form: {
                    id: null,
                    req_visita_seguimiento: null,
                    fecha_visita_seg: null,
                    permite_reg_foto: null,
                    firma_funcionario: null,
                    firma_atiende_visita: null,
                    nombre_alterno: null,
                    iden_alterno: null,
                    firma_alterno: null,
                },
            }
        },
         async mounted(){
             if(this.$route.name=="cierreVisitaVigilanciaControl"){
                this.vigilanciaControl = true;
            }
            if(this.visitaEnGestion.id){
                this.form.id=this.visitaEnGestion.id
                await this.cargarCierreVisita(this.visitaEnGestion.id)
            }
        },
        computed:{
            ...mapState('gestionarVisitas', ['verDetalles','visitaEnGestion', 'cierreVisitaCargada', 'resultCierreVisita' ])
        },
        methods:{
            ...mapActions(['activarLoading']),
            ...mapActions('gestionarVisitas', ['crearCierreVisita', 'cargarCierreVisitaById', 'editarCierreVisita', 'actualizarDatosBasicos']),

            activarCampoAlterno(){
                this.activarAlterno = !this.activarAlterno
                setTimeout(()=>{
                    if(this.form.firma_funcionario){
                        this.$refs.firma_funcionario.fromDataURL(`data:image/png;base64,${this.form.firma_funcionario}`)
                    }
                    if(this.form.firma_atiende_visita){
                        this.$refs.firma_atiende_visita.fromDataURL(`data:image/png;base64,${this.form.firma_atiende_visita}`)
                    }else{
                        if(this.activarAlterno){
                            this.$refs.firma_alterno.fromDataURL(`data:image/png;base64,${this.form.firma_alterno}`)
                        }
                    }
                },500)
            },

            async cargarCierreVisita(id){
                this.activarLoading(true)
                try{
                    await this.cargarCierreVisitaById(id)
                    if(this.cierreVisitaCargada.length>0){
                        this.editar=true
                        this.form=this.cierreVisitaCargada[0]
                        if(this.form.nombre_alterno || this.form.iden_alterno){
                            this.activarAlterno = true
                        }
                        setTimeout(()=>{
                            if(this.form.firma_funcionario){
                                this.$refs.firma_funcionario.fromDataURL(`data:image/png;base64,${this.form.firma_funcionario}`)
                            }
                            if(this.form.firma_atiende_visita){
                                this.$refs.firma_atiende_visita.fromDataURL(`data:image/png;base64,${this.form.firma_atiende_visita}`)
                            }else{
                                if(this.activarAlterno){
                                    this.$refs.firma_alterno.fromDataURL(`data:image/png;base64,${this.form.firma_alterno}`)
                                }
                            }
                        }, 500)
                    }
                    this.activarLoading(false)
                }catch(e){
                    this.activarLoading(false)
                    encontrarError(this, e.data)
                }
            },

            modalEnviar(event){
                event.preventDefault()
                this.$bvModal.msgBoxConfirm('Se van a guardar los datos de Cierre de visita, ¿Desea continuar?.', {
                    title: 'Por favor confirme',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Continuar',
                    cancelTitle: 'Cancelar',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                    })
                    .then(value => {
                        if(value){
                            this.onSubmit()
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },

            async onSubmit() {
                this.activarLoading(true)
                let firmaFuncionario = this.$refs.firma_funcionario.save()
                let baseFirmaFuncionario = firmaFuncionario.split('base64,')
                this.form.firma_funcionario = baseFirmaFuncionario[1]

                if(this.form.req_visita_seguimiento==0){
                    this.form.fecha_visita_seg = null
                }
                
                if(this.activarAlterno){
                    let firmaAlterno = this.$refs.firma_alterno.save()
                    let baseFirmaAlterno = firmaAlterno.split('base64,')
                    this.form.firma_alterno = baseFirmaAlterno[1]
                }else{
                    let firmaAtiende = this.$refs.firma_atiende_visita.save()
                    let baseFirmaAtiende = firmaAtiende.split('base64,')
                    this.form.firma_atiende_visita = baseFirmaAtiende[1]
                }

                let dataGuardar = {
                    cierrevisita: this.form
                }

                console.log('enviado', this.form)

                try{
                    if(this.editar){
                        await this.editarCierreVisita(dataGuardar)
                    }else{
                        await this.crearCierreVisita(dataGuardar)
                    }
                    if(this.resultCierreVisita.data.status==200){
                        await this.actualizarVisita()
                        this.activarLoading(false)
                        this.modalOk()
                    }
                }catch(e){
                    this.activarLoading(false)
                    encontrarError(this, e.data)
                }
            },
           
           async actualizarVisita() {
               let visita = {...this.visitaEnGestion}
               visita.estado=2
                let dataGuardar = {
                    visita: visita
                }

                try{
                    await this.actualizarDatosBasicos(dataGuardar)
                }catch(e){
                    encontrarError(this, e.data)
                }
            },

            modalOk(){
                this.$bvModal.msgBoxOk('Los Datos fueron guardados correctamente.', {
                    title: 'Confirmación',
                    size: 'md',
                    buttonSize: 'md',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                    })
                    .then(() => {
                        if(this.vigilanciaControl){
                            this.$router.push({name: 'gestionarVisitaVigilanciaControl'})
                        }else{
                            this.$router.push({name: 'gestionVisita'})
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            
            cancelar(){
                this.$bvModal.msgBoxOk('Se perderán los datos sin guardar.', {
                    title: 'Cancelar',
                    size: 'md',
                    buttonSize: 'md',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                    })
                    .then(() => {
                        if(this.vigilanciaControl){
                            this.$router.push({name: 'gestionarVisitaVigilanciaControl'})
                        }else{
                            this.$router.push({name: 'gestionVisita'})
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }, 
        }
    }
</script>